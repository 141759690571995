<template>
  <div class="white--text">
    <heading1TextEffect fontSize="48px" text="Crea la tua struttura" />
    <v-layout align-center justify-center>
      <v-flex xs12 sm12 md6 lg6 xl6>
        <v-card class="ma-4">
          <!-- PLANT NAME -->
          <v-card-text>
            <v-text-field
              class="my-8"
              name="plant_name"
              :label="$t('label_new_plant_name')"
              :messages="$t('label_new_plant_name_message_1')"
              v-model.trim="plant_name_user"
              persistent-hint
            >
              <template v-slot:message>
                {{ $t("label_new_plant_name_message_1") }}
                <br />
                {{ $t("label_new_plant_name_message_2") }}
              </template>
            </v-text-field>
            <!-- 
            {{ plant_name }}
            &nbsp; -->

            <!-- PLANT DESCRIPTION -->
            <!-- <hr /> -->
            <v-text-field
              class="my-8"
              name="plant_description"
              :label="$t('label_new_plant_description')"
              :messages="[$t('label_new_plant_description_message_1')]"
              v-model.trim="plant_description"
            >
              <template v-slot:message>
                {{ $t("label_new_plant_description_message_1") }}
                <br />
                {{ $t("label_new_plant_description_message_2") }}
              </template>
            </v-text-field>

            <!-- COMPANY MULTI PLANT -->

            <v-checkbox
              :label="$t('label_new_plant_multi_plant_company')"
              v-model="multiplant_company"
              :messages="
                $t('label_new_plant_multi_plant_company_description_message_1')
              "
            ></v-checkbox>

            <!-- EMPLOYEES -->
            <!-- <hr /> -->
            <v-select
              class="my-8"
              :items="plant_employees_ranges"
              v-model="plant_employees"
              :label="$t('label_new_plant_employees')"
              item-text="text"
              item-value="range"
              :messages="$t('label_new_plant_employees_message_1')"
              persistent-hint
            >
              <template v-slot:message>
                {{ $t("label_new_plant_employees_message_1") }}
                <br />
                {{ $t("label_new_plant_employees_message_2") }}
              </template>
            </v-select>

            {{ plant_employees }}&nbsp;

            <!-- PLANT AREAS COUNTS -->
            <!-- <hr /> -->
            <v-text-field
              class="my-8"
              :label="$t('label_new_plant_area_count')"
              :messages="[$t('label_new_plant_description_message_1')]"
              v-model.trim="plant_description"
            >
              <template v-slot:message>
                {{ $t("label_new_plant_area_count_description_message_1") }}
                <br />
                {{ $t("label_new_plant_area_count_description_message_2") }}
              </template>
            </v-text-field>

            &nbsp;
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import heading1TextEffect from "@/components/landings/heading1TextEffect.vue";

export default {
  props: {},
  components: {
    heading1TextEffect,
  },
  data: () => ({
    plant_name_user: "",
    plant_description: "",
    multiplant_company: false,
    plant_employees: 0,
    plant_employees_ranges: [
      {
        range: 1,
        text: "1 - 5",
      },
      {
        range: 2,
        text: "5 - 25",
      },
      {
        range: 3,
        text: "25 - 100",
      },
      {
        range: 4,
        text: "100 - 250",
      },
      {
        range: 5,
        text: "250+",
      },
    ],
  }),
  methods: {},
  mounted() {},
  created() {},
  computed: {
    plant_name() {
      return this.plant_name_user;
    },
  },
  watch: {},
};
</script>

<style>
</style>