<template>
  <div>
    <div id="header_container_gray_box">
      <v-row no-gutters>
        <!-- Section Payoff -->
        <v-col cols="12" class="d-flex align-center element">
          <!-- Payoff -->
          <h1
            class="ma-0 pa-0"
            style="line-height: normal"
            v-bind:style="{ 'font-size': font_size }"
            v-html="text"
          ></h1>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
    },
  },
  components: {},
  data: () => ({}),
  methods: {},
  mounted() {},
  created() {},
  computed: {
    font_size() {
      if (this.$vuetify.breakpoint.xs) {
        return "1.8rem";
      } else if (this.$vuetify.breakpoint.sm) {
        return "2.5rem";
      } else if (this.$vuetify.breakpoint.md) {
        return "2rem";
      } else if (this.$vuetify.breakpoint.lg) {
        return "3rem";
      } else if (this.$vuetify.breakpoint.xl) {
        return "4rem";
      }
      return "1.5rem";
    },
  },
  watch: {},
};
</script>

<style scoped>
/* Small screens */

@media (max-width: 480px) {
  h1 {
    /* font-size: 1.8rem; */
  }
}

/* Not small Sceens */
@media (min-width: 480px) {
  h1 {
    /* font-size: 4rem; */
  }
}

h1 {
  padding: 0;
  margin: 0;
  /* font-size: 10vw; */
  background: radial-gradient(#eede52, #ff0000);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% 150%;
  animation: gradient 8s ease infinite reverse;
}

@keyframes gradient {
  0% {
    background-position: left top;
  }
  25% {
    background-position: right top;
  }
  50% {
    background-position: left bottom;
  }
  75% {
    background-position: right bottom;
  }
}
</style>